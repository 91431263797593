import Link from 'next/link';
import style from './header.module.sass';
import TopHeaders from '../../Widgets/topHeader/TopHeader';
import { UnderHeader } from '@/Widgets/UnderHeader/UnderHeader';
import Burger from '@/Widgets/MobileBurger/burger';
import NavMobile from '@/Widgets/MobileNav/nav';
import { Suspense } from 'react';
export default function Header() {
	return (
		<>
			<section className={style.HeaderWrapper}>
				<TopHeaders />
			</section>
			<nav className={style.HeaderLowerDiv}>
				<a href={'/'} className={style.svgHeaderLogoCont}>
					<svg
						className={style.SvgLogo}
						width='108'
						height='38'
						viewBox='0 0 108 38'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M41.4292 28.0951L41.3984 27.9082C41.3984 27.9082 38.47 10.0607 38.3775 9.5C38.2542 8.87704 37.9459 8.69016 37.5452 8.69016H37.5144C37.1445 8.69016 36.8979 8.81475 36.6513 9.46885C36.4971 9.87377 31.3493 24.3574 31.3493 24.3574C31.3493 24.3574 31.2877 24.5754 31.0411 24.5754C30.7945 24.5754 30.7328 24.3262 30.7328 24.3262C30.7328 24.3262 25.585 9.84262 25.4309 9.4377C25.1843 8.75245 24.9377 8.65901 24.5678 8.65901H24.5369C24.1362 8.65901 23.828 8.8459 23.7047 9.46885C23.5814 10.0295 20.6838 27.877 20.6838 27.877L20.653 28.0951C20.653 28.1262 20.653 28.1885 20.653 28.2197C20.653 28.8115 21.1462 29.3098 21.7318 29.341H21.7935C22.3175 29.341 22.7491 28.9361 22.8415 28.4066H22.8107L22.8415 28.3754L24.6602 15.3869C24.6602 15.3869 24.6911 15.0131 24.9993 15.0131C25.3076 15.0131 25.4309 15.418 25.4309 15.418L30.178 28.6246L30.2088 28.6869C30.3321 29.0295 30.6095 29.2475 31.0719 29.2475C31.5651 29.2475 31.8117 28.9984 31.935 28.6869L31.9658 28.6246L36.6821 15.418C36.6821 15.418 36.8054 15.0131 37.1137 15.0131C37.3911 15.0131 37.4527 15.3869 37.4527 15.3869L39.2714 28.3443L39.3022 28.3754H39.2714C39.3639 28.9049 39.7955 29.2787 40.3195 29.3098H40.3811C40.9668 29.3098 41.46 28.8115 41.46 28.1885C41.4292 28.1574 41.4292 28.1262 41.4292 28.0951Z'
							fill='white'
						/>
						<path
							d='M16.769 25.3853C16.9539 25.5721 17.0464 25.8525 17.0772 26.1328C17.0772 26.6934 16.6457 27.0049 16.6457 27.0049C14.7961 28.5623 12.5459 29.3098 10.234 29.3098C4.56214 29.3098 0 24.7623 0 19.0312C0 13.3 4.56214 8.69017 10.234 8.69017C12.8233 8.69017 14.7961 9.53115 16.5532 10.9016L16.6765 10.9951C16.9539 11.2443 17.0464 11.4934 17.0464 11.8049C17.0464 11.8049 17.0464 12.1787 16.769 12.5525L16.7381 12.5836C16.4299 12.8639 15.9983 12.8951 15.9983 12.8951C15.7825 12.8951 15.5668 12.8328 15.4126 12.7082C15.3818 12.6771 15.2893 12.6148 15.2893 12.6148C13.8714 11.4934 12.0219 10.7771 10.234 10.7771C5.76433 10.7771 2.3119 14.5771 2.3119 19.0312C2.3119 23.4541 5.7335 27.2541 10.234 27.2541C12.5151 27.2541 13.8714 26.382 15.3202 25.3541C15.9675 24.8869 16.4915 25.1672 16.6765 25.323'
							fill='white'
						/>
						<path
							d='M47.6867 24.6688C47.5018 24.5754 47.5018 24.3574 47.5018 24.3574V9.81147V9.68688C47.4709 9.12622 47.0086 8.69016 46.4537 8.65901H46.3921C45.8064 8.65901 45.3748 9.12622 45.344 9.71803V9.74918V28.1885C45.344 28.7803 45.8064 29.2787 46.4229 29.2787C46.7311 29.2787 47.0086 29.1541 47.2243 28.9361L59.277 13.4246C59.277 13.4246 59.462 13.2377 59.6777 13.3C59.8935 13.3934 59.8935 13.6426 59.8935 13.6426V28.1574V28.282C59.9243 28.8426 60.3867 29.2787 60.9416 29.3098H61.0032C61.5889 29.3098 62.0205 28.8426 62.0513 28.2508V28.2197V9.74918C62.0513 9.15737 61.5889 8.65901 60.9724 8.65901C60.6642 8.65901 60.3559 8.7836 60.1709 9.00163C57.1501 12.9262 48.0874 24.5443 48.0874 24.5443C48.0874 24.5443 47.9641 24.7623 47.6867 24.6688Z'
							fill='white'
						/>
						<path
							d='M107.18 19C107.18 29.4967 98.7643 38 88.3762 38C77.9881 38 69.5728 29.4967 69.5728 19C69.5728 8.50328 77.9881 0 88.3762 0C98.7643 0 107.18 8.50328 107.18 19Z'
							fill='#40AE49'
						/>
						<path
							d='M93.6472 27.3787H93.5548H86.0951C86.0951 27.3787 85.9101 27.4098 85.7868 27.1918C85.6635 26.9738 85.8176 26.818 85.8176 26.818C85.8176 26.818 89.2701 23.0803 90.9963 21.2426C93.0307 19.0312 94.6953 17.0689 94.6953 14.4525C94.6953 11.4 92.5375 8.69017 88.2837 8.69017C85.5402 8.69017 83.6599 10.4656 82.9509 11.2754L82.8892 11.3377C82.7659 11.5246 82.6735 11.7426 82.6735 11.9607C82.6735 12.241 82.7968 12.4902 82.9509 12.6771L82.9817 12.7082C83.1667 12.8639 83.3824 12.9574 83.629 12.9574C83.9065 12.9574 84.1531 12.8328 84.338 12.6459C85.2628 11.7115 86.5883 10.7148 88.222 10.7148C91.2737 10.7148 92.3834 12.8016 92.3834 14.5148C92.3834 15.9164 91.397 17.723 89.3934 19.9344C87.0506 22.4885 84.7387 25.0426 82.3652 27.5967L82.3035 27.659C82.2419 27.7525 82.1802 27.8459 82.1494 27.9394C82.1186 28.0639 82.0878 28.1574 82.0878 28.282C82.0878 28.4066 82.1186 28.5312 82.1494 28.6246C82.3035 28.9984 82.6118 29.2787 83.0434 29.2787H83.105H93.5239H93.6164C94.1096 29.2475 94.5104 28.8426 94.5412 28.3443V28.2508C94.5104 27.7836 94.1405 27.3787 93.6472 27.3787Z'
							fill='white'
						/>
					</svg>
				</a>

				<Suspense>
					<UnderHeader />
					<NavMobile />
					<Burger />
				</Suspense>
			</nav>
		</>
	);
}
