import Link from 'next/link';

//----------------------------------------
//стили
import style from './TopHeader.module.sass';

//----------------------------------------
//  Хуки
import { Suspense } from 'react';

// всякие Redux приколы
import { TopHeaderLinks } from '@/Features/TopHeaderLinks/TopHederLinks';
import { GlobalSvgSelector } from '@/lib/GlobalSVGSelector';
import GeoTopHeader from './GeoTopHeader/GeoTopHeader';

// ==================================

export default function TopHeaders() {
	return (
		<section className={style.HeaderUpperDiv}>
			<TopHeaderLinks />
			<section className={style.NavLocation}>
				<GlobalSvgSelector id='top-header-18' />
				<nav>
					<GlobalSvgSelector id='top-header-arrow' />
					<Suspense>
						<GeoTopHeader />
					</Suspense>
					<span className={style.signIn}>
						<Link
							// style={{ fontSize: `${13 + CurrentFontSize}px` }}
							target='_blank'
							className={style.signIn}
							href={'https://smi2.net/dashboard/login'}
						>
							Войти
						</Link>
					</span>
				</nav>
			</section>
		</section>
	);
}
