'use client';
import Header from '@/components/Header/header';
import Link from 'next/link';
import InfoLine from '@/Widgets/InfoLine/date';
import { BtnScrollUp } from '@/Features/BtnScrollUp/ScrollUp';
import Sidebar from '@/components/Sidebar/sidebar';
import '@/app/global.css';

export default function Error({
	error,
	reset
}: {
	error: Error;
	reset: () => void;
}) {
	return (
		<div
			lang='ru'
			style={{
				fontSize: '12px',
				padding: '0',
				margin: '0',
				display: 'flex',
				justifyContent: 'center',
				background: 'var(--main-background-color)',
				WebkitTapHighlightColor: 'transparent',
				outline: 'none',
				width: '100%',
				height: '100%',
				boxSizing: 'border-box'
			}}
		>
			<div className='mainDivLayout'>
				<Header />
				<section className='mainSection'>
					<section className='contentSection'>
						<InfoLine />
						<BtnScrollUp />
						<div className='ErrorCont'>
							<div className='WaitNews'>{'Ждём новостей :)'}</div>
							<div className='Publick'>В этой рубрике пока нет новостей</div>
							<Link href='/'>
								<button className='BtnAtHome'>На главную</button>
							</Link>
						</div>
					</section>
					<section className='sidebarSection'>
						<Sidebar />
					</section>
				</section>
			</div>
		</div>
	);
}
