import Quiz from '@/Widgets/QuizSlider/quiz';
import Quote from '@/Widgets/QuoteSlider/quote';
import Video from '@/Widgets/VideoSlider/video';
import style from './sidebar.module.sass';
import Licence from '@/Widgets/LicenceBlock/licence';
import Upload from '@/Widgets/UploadBlock/upload';
import { Suspense } from 'react';

// -------------------------------------------

export default function Sidebar() {
	return (
		<>
			<section className={style.Wrapper}>
				{/* <Audio />
				<div className={style.delimiterDiv}>
					<span className={style.delimiter}></span>
				</div> */}
				<div style={{ minHeight: '150px' }}>
					<Suspense>
						<Video />
					</Suspense>
				</div>
				<div className={style.delimiterDiv}>
					<span className={style.delimiter}></span>
				</div>
				<div style={{ minHeight: '150px' }}>
					<Suspense>
						<Quote />
					</Suspense>
				</div>
				<div className={style.delimiterDiv}>
					<span className={style.delimiter}></span>
				</div>
				<div style={{ minHeight: '150px' }}>
					<Suspense>
						<Quiz />
					</Suspense>
				</div>
			</section>
			<section className={style.stickySection}>
				<div className={style.adfoxCnt}>
					<div className={style.part}>У ПАРТНЕРОВ СМИ2</div>
					<div id='adfox_159067343332936426'></div>
				</div>

				<div className={style.delimiterDiv}>
					<span className={style.delimiter}></span>
				</div>
				<Suspense>
					<Licence />
				</Suspense>
				<div className={style.delimiterDiv}>
					<span className={style.delimiter}></span>
				</div>

				<Upload />
			</section>
		</>
	);
}
