// некстовые приколы
import Link from 'next/link';

//----------------------------------------
//стили
import style from './TopHederLinks.module.sass';

// хуки
// import { useAppSelector } from '@/Hooks/redux';

export const TopHeaderLinks = () => {
	// размер шрифта
	// const { CurrentFontSize } = useAppSelector(state => state.HeaderState);
	return (
		<ul className={style.NavMenu}>
			<li>
				<Link target='_blank' className={style.Link} href={'https://smi2.ru/'}>
					КАРТИНА ДНЯ
				</Link>
			</li>
			<li>
				<Link
					target='_blank'
					className={style.Link}
					href={
						'https://smi2.ru/newdata/adpreview?ad=0&bl=92448&ct=adpreview&ag=0&sct=portalmenu'
					}
				>
					ПОПУЛЯРНОЕ
				</Link>
			</li>
			<li>
				<Link
					target='_blank'
					className={style.Link}
					href={'https://lady.smi2.ru/'}
				>
					СМИ2 LADY
				</Link>
			</li>
			<li>
				<a
					target='_blank'
					className={style.Link}
					href={'https://smi2.net/corporate/advertisers/'}
				>
					РЕКЛАМА В СМИ
				</a>
			</li>
		</ul>
	);
};
