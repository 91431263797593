import Link from 'next/link';
import style from './upload.module.sass';

export default function Upload() {
	return (
		<section className={style.Wrapper}>
			<span className={style.LoadAnnounce}>
				Скачайте мобильное приложение СМИ2:
			</span>
			<ul className={style.LoadingList}>
				<li>
					<svg
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M17.8747 11.804C17.885 11.0072 18.0966 10.2261 18.4899 9.53311C18.8832 8.84015 19.4454 8.25794 20.1242 7.84064C19.6929 7.2248 19.1241 6.718 18.4628 6.36047C17.8014 6.00294 17.0658 5.80454 16.3144 5.78103C14.7115 5.61279 13.1575 6.74019 12.3407 6.74019C11.5081 6.74019 10.2505 5.79774 8.89625 5.8256C8.02029 5.8539 7.1666 6.10863 6.41835 6.56495C5.6701 7.02128 5.05282 7.66365 4.62665 8.42948C2.78056 11.6257 4.15757 16.3231 5.92597 18.9066C6.81074 20.1717 7.84478 21.5849 9.1978 21.5348C10.5218 21.4799 11.0163 20.6905 12.6145 20.6905C14.1979 20.6905 14.6618 21.5348 16.0423 21.5029C17.463 21.4799 18.3581 20.2322 19.2119 18.9551C19.8476 18.0537 20.3367 17.0574 20.6613 16.0032C19.8359 15.6541 19.1315 15.0697 18.6359 14.323C18.1404 13.5762 17.8757 12.7002 17.8747 11.804Z'
							fill='#6B6B6B'
						/>
						<path
							d='M15.2674 4.08195C16.0421 3.15203 16.4237 1.95677 16.3313 0.75C15.1478 0.874301 14.0546 1.43993 13.2695 2.33417C12.8857 2.77104 12.5917 3.27927 12.4043 3.82982C12.217 4.38038 12.14 4.96245 12.1778 5.54277C12.7698 5.54887 13.3554 5.42056 13.8905 5.16753C14.4257 4.91449 14.8965 4.54331 15.2674 4.08195Z'
							fill='#6B6B6B'
						/>
					</svg>
					<Link
						className={style.Links}
						href={
							'https://redirect.appmetrica.yandex.com/serve/821520998380698337'
						}
						target='_blank'
					>
						App Store
					</Link>
				</li>
				<li>
					<svg
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M11.7832 12.7283L3.99609 20.5212C4.38281 21.1218 4.77653 21.4055 5.44043 21.5496C5.64258 21.5935 6.04395 21.6316 6.45703 21.5115L6.85547 21.3474L15.4485 16.3984L11.7832 12.7283Z'
							fill='#6B6B6B'
						/>
						<path
							d='M19.772 10.1337L19.7644 10.1285L16.3682 8.1521L12.1582 12.3621L15.9186 16.1252L19.7591 13.9093C20.4325 13.5458 20.8896 12.8359 20.8896 12.0174C20.8896 11.2047 20.4386 10.4984 19.772 10.1337Z'
							fill='#6B6B6B'
						/>
						<path
							d='M3.71582 4.66272V19.4504C3.71582 19.6416 3.74063 19.8267 3.78898 20.0022L11.411 12.3649L3.71582 4.66272Z'
							fill='#6B6B6B'
						/>
						<path
							d='M11.7924 12L15.9047 7.88891L6.97178 2.70978C6.64708 2.51531 6.26814 2.40308 5.86263 2.40308C4.87004 2.40308 4.0326 3.07851 3.78887 3.99528L3.78857 3.99759L11.7924 12Z'
							fill='#6B6B6B'
						/>
					</svg>
					<Link
						className={style.Links}
						href={
							'https://redirect.appmetrica.yandex.com/serve/1179079506955168323'
						}
						target='_blank'
					>
						Google Play
					</Link>
				</li>
				<li>
					<svg
						width='22'
						height='21'
						viewBox='0 0 22 21'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<g clip-path='url(#clip0_402_38)'>
							<path
								fill-rule='evenodd'
								clip-rule='evenodd'
								d='M10.4101 20.8571C5.7384 20.8571 3.40256 20.8571 1.95125 19.4261C0.5 17.9952 0.5 15.692 0.5 11.0857V10.2714C0.5 5.66508 0.5 3.36194 1.95125 1.93094C3.40256 0.5 5.7384 0.5 10.4101 0.5H11.2359C15.9076 0.5 18.2434 0.5 19.6947 1.93094C21.146 3.36194 21.146 5.66508 21.146 10.2714V11.0857C21.146 15.692 21.146 17.9952 19.6947 19.4261C18.2434 20.8571 15.9076 20.8571 11.2359 20.8571H10.4101ZM15.0443 13.6869L16.4393 14.0305C17.0711 14.1861 17.6836 13.7174 17.6836 13.0782V6.44877C17.6836 5.88604 17.2936 5.39542 16.7373 5.2584L12.7362 4.2729C12.1043 4.11725 11.4918 4.58606 11.4918 5.22517V6.42027L8.97083 5.79933C8.33904 5.64367 7.72652 6.11248 7.72652 6.7516V7.94669L5.20537 7.32569C4.57358 7.17009 3.96106 7.63885 3.96106 8.27796V14.9074C3.96106 15.4702 4.35106 15.9608 4.90733 16.0978L8.90846 17.0833C9.54025 17.239 10.1528 16.7702 10.1528 16.131V15.0288L10.1531 9.51125C10.1531 8.95198 9.79479 8.62775 9.66458 8.5284C9.63977 8.50942 9.63291 8.47411 9.65067 8.44856C9.66821 8.42346 9.69983 8.41163 9.72942 8.4204C10.2566 8.57738 10.761 9.11029 10.8114 9.78033L10.9854 14.8432C10.9912 15.0096 11.1003 15.1538 11.2573 15.208L12.6739 15.5569C13.3057 15.7125 13.9182 15.2438 13.9182 14.6047L13.9193 7.98552C13.9193 7.42625 13.561 7.10202 13.4308 7.00261C13.406 6.98369 13.3991 6.94838 13.4169 6.92283C13.4344 6.89773 13.466 6.88585 13.4956 6.89467C14.0228 7.0516 14.5272 7.58456 14.5776 8.2546L14.7517 13.3172C14.7577 13.4903 14.8763 13.6394 15.0443 13.6869Z'
								fill='#6B6B6B'
							/>
						</g>
						<defs>
							<clipPath id='clip0_402_38'>
								<rect width='22' height='21' fill='white' />
							</clipPath>
						</defs>
					</svg>
					<Link
						className={style.Links}
						href={
							'https://redirect.appmetrica.yandex.com/serve/1037432840024809112?c={{campaign_id}}&gender={{gender}}&click_id={clickid}&google_aid_sha1={sha1_gaid}&banner_id={{banner_id}}&pid=mail.ru_int&age={{age}}'
						}
						target='_blank'
					>
						<div style={{ marginLeft: '3px' }}>RuStore</div>
					</Link>
				</li>
				<li>
					<svg
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							fill-rule='evenodd'
							clip-rule='evenodd'
							d='M7.2784 1.5H16.2597C20.5015 1.5 22.0597 3.05283 22.0381 7.25846V16.2089C22.0381 20.4145 20.5015 21.9673 16.2597 21.9673H7.2784C3.05823 21.9673 1.5 20.436 1.5 16.2089V7.25846C1.5 3.05283 3.03656 1.5 7.2784 1.5ZM11.78 8.33701C9.89716 8.33701 8.3606 6.80574 8.3606 4.92938H8.83674C8.83674 6.54688 10.1568 7.86253 11.78 7.86253C13.4032 7.86253 14.7233 6.54688 14.7233 4.92938H15.1994C15.1994 6.80574 13.6628 8.33701 11.78 8.33701ZM5.56889 11.7447H6.10994V14.419H5.56889V13.3406H4.33535V14.419H3.79431V11.7447H4.33535V12.823H5.56889V11.7447ZM8.64202 13.2759C8.64202 13.7072 8.42562 13.9445 8.03609 13.9445C7.6465 13.9445 7.4301 13.7072 7.4301 13.2543V11.7447H6.88905V13.2759C6.88905 14.0308 7.30025 14.4621 8.03609 14.4621C8.77192 14.4621 9.20474 14.0308 9.20474 13.2543V11.7447H8.66369V13.2759H8.64202ZM14.9399 13.5778L14.3339 11.7447H13.8794L13.2734 13.5778L12.6891 11.7447H12.1047L13.0353 14.419H13.4898L14.0958 12.6504L14.7018 14.419H15.1563L16.0868 11.7447H15.5241L14.9399 13.5778ZM17.104 13.2759H18.0779V12.7798H17.0824V12.2407H18.5324V11.7447H16.563V14.419H18.5973V13.9229H17.104V13.2759ZM19.2249 14.419H19.766V11.7447H19.2249V14.419ZM10.2652 13.8583L10.0271 14.419H9.46442L10.6331 11.7447H11.1092L12.2779 14.419H11.7152L11.4771 13.8583H10.2652ZM10.4599 13.3837H11.2823L10.8711 12.4348L10.4599 13.3837Z'
							fill='#6B6B6B'
						/>
					</svg>
					<Link
						className={style.Links}
						href={
							'https://redirect.appmetrica.yandex.com/serve/28887524344665491'
						}
						target='_blank'
					>
						AppGallery
					</Link>
				</li>
			</ul>
		</section>
	);
}
